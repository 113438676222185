import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faCaretLeft,
} from "@fortawesome/free-solid-svg-icons";
const SlidingToolbar = (props) => {
  const scrollRef = useRef();

  return (
    <div className="sliding-toolbar">
      <FontAwesomeIcon
        icon={faAngleLeft}
        className="row-btn"
        onClick={() => {
          scrollRef.current.scrollLeft = scrollRef.current.scrollLeft - 30;
        }}
      />
      <div className="sliding-box" ref={scrollRef}>
        {props.children}
      </div>
      <FontAwesomeIcon
        icon={faAngleRight}
        className="row-btn"
        onClick={() => {
          scrollRef.current.scrollLeft = scrollRef.current.scrollLeft + 30;
        }}
      />
    </div>
  );
};

export default SlidingToolbar;
