import React, { useState } from "react";
import ImagesGrid from "../../components/ImagesGrid/ImagesGrid";
import worksList from "../../components/Works/worksList";
import { NormalizeWork } from "../../utils/worksUtils";
import WorkPopup from "../../components/WorkPopup";

const AllWorks = () => {
  const [currentWork, setCurrentWork] = useState(undefined);

  const openPopup = (work) => {
    document.body.style.overflow = "hidden";
    setCurrentWork(work);
  };

  const closePopup = () => {
    document.body.style.overflow = "visible";
    setCurrentWork(undefined);
  };

  let selectedWorks = worksList;

  selectedWorks.map((w) => {
    NormalizeWork(w);
    w.onClick = () => {
      openPopup(w);
    };
    return w;
  });

  return (
    <div className="page-section">
      <h1 className="title text-center">Selected works</h1>
      <ImagesGrid images={selectedWorks} />
      <WorkPopup work={currentWork} closePopup={closePopup} />
    </div>
  );
};

export default AllWorks;
