const navLinks = [
  {
    name: "HOME",
    url: "/",
  },
  {
    name: "WORKS",
    submenu: [
      {
        name: "Copper sculptures",
        url: "/category/copper-sculptures",
      },
      {
        name: "Land art",
        url: "/category/land-art",
      },
      {
        name: "Iron sculptures",
        url: "/category/iron-sculptures",
      },
      {
        name: "Limited edition prints",
        url: "/category/limited-edition-prints",
      },
      {
        name: "Plaster and composite",
        url: "/category/plaster-and-composite",
      },
    ],
  },

  {
    name: "ABOUT",
    url: "/about",
  },

  {
    name: "CONTACTS",
    url: "/contacts",
  },
];

export default navLinks;
