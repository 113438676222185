import "./App.scss";
import Home from "./pages/Home/Home";
import Navbar from "./components/Navbar/Navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Category from "./pages/Category";
import Footer from "./components/Footer";
import Policy from "./pages/Policy";
import AllWorks from "./pages/AllWorks/AllWorks";
import ScrollToTop from "./ScrollToTop";
import AboutPage from "./pages/About";
import ContactsPage from "./pages/Contacts/ContactsPage";

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Navbar />
        <main className="main">
          <Switch>
            <Route
              path="/"
              exact
              component={() => (
                <h1 className="home-title text-center">Matteo Baroni</h1>
              )}
            />
          </Switch>
          <section className="main-section">
            <Switch>
              <Route path="/" exact component={() => <Home />} />
              <Route path="/about" exact component={() => <AboutPage />} />
              <Route
                path="/category/:category"
                exact
                component={() => <Category />}
              />
              <Route
                path="/contacts"
                exact
                component={() => <ContactsPage />}
              />
              <Route path="/works" exact component={() => <AllWorks />} />
              <Route
                path="/privacy-policy"
                exact
                component={() => (
                  <Policy filePath="assets/policies/privacy-policy.md" />
                )}
              />
            </Switch>
            <Footer />
          </section>
        </main>
      </Router>
    </>
  );
}

export default App;
