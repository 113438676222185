import React, { useState } from "react";
import ContactForm from "../ContactForm/ContactForm";
import ImagesGrid from "../ImagesGrid/ImagesGrid";
import { useTranslation } from "react-i18next";

import { HashLink as Link } from "react-router-hash-link";

import worksList from "./worksList";
import SlidingToolbar from "../SlidingToolbar";

import { NormalizeWork } from "../../utils/worksUtils";

const dates = [
  "2021",
  "2019-2020",
  "2017-2018",
  "2015-2016",
  "2013-2014",
  "2011-2012",
  "2009-2010",
];

const Works = () => {
  const [dateClicked, setDateCLicked] = useState(dates[0]);
  const [currentWork, setCurrentWork] = useState(undefined);
  const { t } = useTranslation();

  const openPopup = (work) => {
    document.body.style.overflow = "hidden";
    setCurrentWork(work);
  };

  const closePopup = () => {
    document.body.style.overflow = "visible";
    setCurrentWork(undefined);
  };

  let selectedWorks = worksList.filter((work) => {
    return work.date === dateClicked;
  });

  selectedWorks = selectedWorks.slice(0, 4);

  selectedWorks.map((w) => {
    NormalizeWork(w);
    w.onClick = () => {
      openPopup(w);
    };

    return w;
  });

  return (
    <>
      <div className="works padding-box">
        <SlidingToolbar>
          <ul className="works-toolbar">
            {dates.map((date) => {
              return (
                <li
                  className={dateClicked === date ? "clicked" : ""}
                  onClick={() => {
                    setDateCLicked(date);
                  }}
                >
                  {date}
                </li>
              );
            })}
          </ul>
        </SlidingToolbar>

        <ImagesGrid images={selectedWorks} mode="grid" />
        <div className="flex">
          <Link
            className="button"
            to="/works"
            style={{ margin: "auto", display: "inline-block" }}
          >
            {t("general.view_all")}
          </Link>
        </div>
      </div>
      <div
        className={currentWork ? "works-popup" : "works-popup hidden"}
        onClick={(e) => {
          if (e.target.className === "works-popup") closePopup();
        }}
      >
        <div className="content-box shadow">
          {currentWork ? (
            <>
              <p className="bold">
                {currentWork.title}, {currentWork.date}
              </p>
              <p>{currentWork.description}</p>
              <div className="images flex v-flex ai-flex jc-flex">
                {currentWork.gallery.map((img) => {
                  return <img src={img} alt="" className="work-image" />;
                })}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="content-box shadow">
          <ContactForm />
        </div>
      </div>
    </>
  );
};

export default Works;
