import React from "react";
import { HashLink as Link } from "react-router-hash-link";
const Footer = () => {
  return (
    <div className="footer">
      <p>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </p>
      <p></p>
    </div>
  );
};

export default Footer;
