import React, { useState, useEffect, useRef, useCallback } from "react";
import useResizeObserver from "@react-hook/resize-observer";

const useContainerDimensions = (myRef) => {
  const getDimensions = () => ({
    width: myRef.current.offsetWidth,
    height: myRef.current.offsetHeight,
  });

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef]);

  return dimensions;
};

const useSize = () => {
  let observer = useRef();

  const nullSize = { width: null, height: null };

  const [currentSize, setCurrentSize] = useState(nullSize);

  const getSize = (elements) => {
    const el = elements[0].target;

    if (!el) return;

    let width = el.offsetWidth ?? 0;
    let height = el.offsetHeight ?? 0;

    setCurrentSize({ width, height });
  };

  const target = useCallback((element) => {
    if (element) {
      observer.current = new ResizeObserver(getSize).observe(element);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, []);

  return [target, currentSize];
};

const ImagesGrid = (props) => {
  const [rowHeight, setRowHeight] = useState();

  //const ref = useRef(null);
  //const { width, height } = useSize(ref);
  const [target, currentSize] = useSize();
  const width = currentSize.width;
  const height = currentSize.height;
  useEffect(() => {
    setRowHeight(width / 2.7);
  }, [width]);

  let selectedImages = props.images;
  let images = [];

  let mode = props.mode;
  if (mode === "grid") {
    if (selectedImages.length === 1) {
      images.push(
        <img
          src={selectedImages[0].image}
          alt=""
          className="work-image clickable"
          onClick={selectedImages[0].onClick}
        />
      );
    }

    for (let i = 0; i < selectedImages.length - 1; i += 2) {
      let firstImageSizeRatio =
        selectedImages[i].sizes.w / selectedImages[i].sizes.h;
      let secondImageSizeRatio =
        selectedImages[i + 1].sizes.w / selectedImages[i + 1].sizes.h;
      /*console.log(
        i,
        selectedImages[i].title,
        "First:",
        firstImageSizeRatio,
        "Second: ",
        secondImageSizeRatio,
        "Tot: ",
        firstImageSizeRatio + secondImageSizeRatio,
        "Eval:",
        width / (firstImageSizeRatio + secondImageSizeRatio)
      );*/
      images.push(
        <div
          className="image-row"
          style={{
            height: width / (firstImageSizeRatio + secondImageSizeRatio + 0.05),
          }}
        >
          <img
            src={selectedImages[i].image}
            alt=""
            className="image-item clickable"
            onClick={selectedImages[i].onClick}
          />
          <img
            src={selectedImages[i + 1].image}
            alt=""
            className="image-item clickable"
            onClick={selectedImages[i + 1].onClick}
          />
        </div>
      );

      if (i === selectedImages.length - 3) {
        images.push(
          <img
            src={selectedImages[i + 2].image}
            alt=""
            className="work-image clickable"
            onClick={selectedImages[i + 2].onClick}
          />
        );
      }
    }
  } else {
    selectedImages.forEach((img) => {
      images.push(
        <img
          src={img.image}
          alt=""
          className="work-image"
          onClick={img.onClick}
        />
      );
    });
  }

  return (
    <div className="images flex h-flex ai-flex jc-flex" ref={target}>
      {images}
    </div>
  );
};

export default ImagesGrid;
