import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const InstagramBox = () => {
  const [mediaItems, setMediaItems] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    (async function () {
      try {
        const response = await fetch("/assets/instagram/posts.json", {});
        const json = await response.json();
        setMediaItems(json);
      } catch (e) {
        console.log("Error on fetching instagram data: ", e);
      }
    })();
  }, []);
  return (
    <div className="padding-box instagram-box">
      <a
        className="header"
        href="https://www.instagram.com/matteo_baroni_sculptures/"
        target="_blank"
      >
        <img src="/assets/instagram/media/logo.jpg" alt="" className="logo" />
        <div className="name-box">
          <p className="title text-center bold">MATTEO BARONI</p>
          {/*<p className="text-small text-center">180K FOLLOWERS</p>*/}
        </div>
      </a>
      <a
        href="https://www.instagram.com/matteo_baroni_sculptures/"
        target="_blank"
        className="follow-me"
      >
        <p>{t("general.follow_me_on_instagram")}</p>
        <FontAwesomeIcon icon={faInstagram} className="icon" />
      </a>
      <div className="instagram-grid">
        {mediaItems.map((item) => (
          <a className="post" href={item.url} target="_blank">
            <img
              src={"/assets/instagram/media/" + item.image_filename}
              alt=""
              className="image"
            />
            {item.is_video && (
              <FontAwesomeIcon icon={faPlay} className="play" />
            )}
          </a>
        ))}
      </div>
    </div>
  );
};

export default InstagramBox;
