import React, { useState } from "react";
//import { Collapse } from "react-collapse";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

const NavSubmenu = (props) => {
  const [expand, setExpand] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <li className="nav-link">
        <p
          style={{ cursor: "pointer" }}
          className="noselect"
          onClick={() => {
            setExpand(!expand);
          }}
        >
          {t("navbar." + props.link.name)}
        </p>
      </li>
      <div className={expand ? "content" : "content-hidden"}>
        {props.link.submenu.map((elem) => {
          return (
            <li className="submenu-link">
              <Link
                to={elem.url}
                className="noselect"
                onClick={() => {
                  props.setExpand(false);
                }}
              >
                {elem.name}
              </Link>
            </li>
          );
        })}
      </div>
    </>
  );
};

export default NavSubmenu;
