const worksList = [
  {
    title: "Bull India",
    category: "copper-sculptures",
    date: "2017-2018",
    imageName: "1.jpg",
    sizes: { w: 900, h: 675 },
    imagesName: ["1.jpg", "2.jpg"],
  },
  {
    title: "Homo Smartphoniensis",
    category: "copper-sculptures",
    date: "2017-2018",
    imageName: "1.jpg",
    sizes: { w: 800, h: 536 },
    imagesName: [
      "1.jpg",
      "2.jpg",
      "3.jpg",
      "4.jpg",
      "5.jpg",
      "6.jpg",
      "7.jpg",
      "8.jpg",
      "9.jpg",
      "10.jpg",
      "11.jpg",
      "12.jpg",
      "13.jpg",
      "14.jpg",
      "15.jpg",
    ],
  },

  {
    title: "Man with Chain",
    category: "copper-sculptures",
    date: "2017-2018",
    suffix: "2",
    imageName: "1.jpg",
    sizes: { w: 817, h: 962 },
    imagesName: [
      "1.jpg",
      "2.jpg",
      "3.jpg",
      "4.jpg",
      "5.jpg",
      "6.jpg",
      "7.jpg",
      "8.jpg",
    ],
  },
  {
    title: "Pause for Thought",
    category: "copper-sculptures",
    date: "2017-2018",
    suffix: "2",
    imageName: "1.jpg",
    sizes: { w: 850, h: 680 },
    imagesName: ["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg"],
  },
  {
    title: "Selfie destruction",
    category: "copper-sculptures",
    date: "2017-2018",
    imageName: "1.jpg",
    sizes: { w: 750, h: 502 },
    imagesName: [
      "1.jpg",
      "2.jpg",
      "3.jpg",
      "4.jpg",
      "5.jpg",
      "6.jpg",
      "7.jpg",
      "8.jpg",
      "9.jpg",
      "10.jpg",
      "11.jpg",
      "12.jpg",
    ],
  },

  {
    title: "Philipa front",
    category: "copper-sculptures",
    date: "2015-2016",
    imageName: "1.jpg",
    sizes: { w: 900, h: 1200 },
    imagesName: ["1.jpg", "2.jpg", "3.jpg"],
  },
  {
    title: "Philipa retro",
    category: "copper-sculptures",
    date: "2015-2016",
    imageName: "1.jpg",
    sizes: { w: 900, h: 1200 },
    imagesName: ["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg"],
  },
  {
    title: "Squalo",
    category: "copper-sculptures",
    date: "2015-2016",
    imageName: "1.jpg",
    sizes: { w: 800, h: 288 },
    imagesName: [
      "1.jpg",
      "2.jpg",
      "3.jpg",
      "4.jpg",
      "5.jpg",
      "6.jpg",
      "7.jpg",
      "8.jpg",
      "9.jpg",
      "10.jpg",
      "11.jpg",
      "12.jpg",
      "13.jpg",
      "14.jpg",
      "15.jpg",
      "16.jpg",
      "17.jpg",
    ],
  },

  {
    title: "From surface to structure",
    category: "copper-sculptures",
    date: "2013-2014",
    imageName: "1.jpg",
    sizes: { w: 900, h: 675 },
    imagesName: ["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg"],
  },
  {
    title: "One step forward to step back",
    category: "copper-sculptures",
    date: "2013-2014",
    imageName: "1.jpg",
    sizes: { w: 900, h: 675 },
    imagesName: ["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg"],
  },
  {
    title: "Pause for Thought",
    category: "copper-sculptures",
    date: "2013-2014",
    imageName: "1.jpg",
    sizes: { w: 900, h: 1200 },
    imagesName: ["1.jpg", "2.jpg", "3.jpg"],
  },
  {
    title: "Philipa toscanella",
    category: "copper-sculptures",
    date: "2013-2014",
    imageName: "1.jpg",
    sizes: { w: 900, h: 1200 },
    imagesName: ["1.jpg", "2.jpg"],
  },
  {
    title: "Equilibristi",
    category: "copper-sculptures",
    date: "2011-2012",
    imageName: "1.jpg",
    sizes: { w: 900, h: 1104 },
    imagesName: [
      "1.jpg",
      "2.jpg",
      "3.jpg",
      "4.jpg",
      "5.jpg",
      "6.jpg",
      "7.jpg",
      "8.jpg",
      "9.jpg",
      "10.jpg",
      "11.jpg",
    ],
  },
  {
    title: "Heroes",
    category: "copper-sculptures",
    date: "2011-2012",
    imageName: "1.jpg",
    sizes: { w: 900, h: 1091 },
    imagesName: ["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg"],
  },
  {
    title: "Idiocameras",
    category: "copper-sculptures",
    date: "2011-2012",
    imageName: "1.jpg",
    sizes: { w: 900, h: 1104 },
    imagesName: ["1.jpg", "2.jpg", "3.jpg"],
  },
  {
    title: "Horizon",
    category: "copper-sculptures",
    date: "2009-2010",
    imageName: "1.jpg",
    sizes: { w: 900, h: 602 },
    imagesName: ["1.jpg", "2.jpg"],
  },
  {
    title: "Autoritratto",
    category: "copper-sculptures",
    date: "2009-2010",
    imageName: "1.jpg",
    sizes: { w: 900, h: 600 },
    imagesName: ["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg"],
  },
  {
    title: "NW11",
    category: "copper-sculptures",
    date: "2009-2010",
    imageName: "1.jpg",
    sizes: { w: 860, h: 692 },
    imagesName: ["1.jpg", "2.jpg", "3.jpg", "4.jpg"],
  },

  {
    title: "Letizia",
    category: "copper-sculptures",
    date: "2009-2010",
    imageName: "1.jpg",
    sizes: { w: 900, h: 602 },
    imagesName: [
      "1.jpg",
      "2.jpg",
      "3.jpg",
      "4.jpg",
      "5.jpg",
      "6.jpg",
      "7.jpg",
      "8.jpg",
    ],
  },
  {
    title: "Manhattan",
    category: "copper-sculptures",
    date: "2009-2010",
    imageName: "1.jpg",
    sizes: { w: 900, h: 1344 },
    imagesName: ["1.jpg", "2.jpg", "3.jpg", "4.jpg"],
  },
  {
    title: "Man with Chain",
    category: "copper-sculptures",
    date: "2009-2010",
    imageName: "1.jpg",
    sizes: { w: 900, h: 1344 },
    imagesName: ["1.jpg", "2.jpg", "3.jpg"],
  },
  {
    title: "Man with Kite",
    category: "copper-sculptures",
    date: "2009-2010",
    imageName: "1.jpg",
    sizes: { w: 900, h: 686 },
    imagesName: ["1.jpg", "2.jpg", "3.jpg"],
  },
  {
    title: "Philipa",
    category: "copper-sculptures",
    date: "2009-2010",
    imageName: "1.jpg",
    sizes: { w: 900, h: 602 },
    imagesName: ["1.jpg", "2.jpg", "3.jpg", "4.jpg"],
  },
  {
    title: "Florence",
    category: "copper-sculptures",
    date: "2009-2010",
    imageName: "1.jpg",
    sizes: { w: 900, h: 1344 },
    imagesName: ["1.jpg", "2.jpg", "3.jpg", "4.jpg"],
  },
  {
    title: "Tightrope Walker",
    category: "copper-sculptures",
    date: "2009-2010",
    imageName: "1.jpg",
    sizes: { w: 900, h: 1344 },
    imagesName: ["1.jpg", "2.jpg", "3.jpg", "4.jpg"],
  },

  /*--------*/

  {
    title: "Bilancieri",
    category: "iron-sculptures",
    date: "2009-2010",
    imageName: "1.jpg",
    sizes: { w: 800, h: 1195 },
    imagesName: [
      "1.jpg",
      "2.jpg",
      "3.jpg",
      "4.jpg",
      "5.jpg",
      "6.jpg",
      "7.jpg",
      "8.jpg",
    ],
  },
  {
    title: "Lavori vari",
    category: "iron-sculptures",
    date: "2021",
    imageName: "1.jpg",
    sizes: { w: 800, h: 800 },
    imagesName: ["1.jpg", "2.jpg", "3.jpg", "4.jpg"],
  },

  {
    title: "Flockers sfera",
    category: "iron-sculptures",
    date: "2019-2020",
    imageName: "1.jpg",
    sizes: { w: 800, h: 600 },
    imagesName: [
      "1.jpg",
      "2.jpg",
      "3.jpg",
      "4.jpg",
      "5.jpg",
      "6.jpg",
      "7.jpg",
      "8.jpg",
    ],
  },

  {
    title: "Flockers trampolieri",
    category: "iron-sculptures",
    date: "2021",
    imageName: "1.jpg",
    sizes: { w: 750, h: 750 },
    imagesName: ["1.jpg", "2.jpg", "3.jpg", "4.jpg"],
  },
  {
    title: "Opera viva",
    category: "land-art",
    date: "2019-2020",
    imageName: "1.jpg",
    sizes: { w: 800, h: 800 },
    imagesName: [
      "1.jpg",
      "2.jpg",
      "3.jpg",
      "4.jpg",
      "5.jpg",
      "6.jpg",
      "7.jpg",
      "8.jpg",
      "9.jpg",
      "10.jpg",
      "11.jpg",
      "12.jpg",
      "13.jpg",
      "14.jpg",
      "15.jpg",
      "16.jpg",
      "17.jpg",
      "18.jpg",
      "19.jpg",
      "20.jpg",
      "21.jpg",
      "22.jpg",
      "23.jpg",
    ],
  },
  {
    title: "Ricucire",
    category: "land-art",
    date: "2019-2020",
    imageName: "1.jpg",
    sizes: { w: 800, h: 600 },
    imagesName: [
      "1.jpg",
      "2.jpg",
      "3.jpg",
      "4.jpg",
      "5.jpg",
      "6.jpg",
      "7.jpg",
      "8.jpg",
      "9.jpg",
      "10.jpg",
      "11.jpg",
      "12.jpg",
      "13.jpg",
      "14.jpg",
    ],
  },
  {
    title: "Legacy",
    category: "plaster-and-composite",
    date: "2019-2020",
    imageName: "1.jpg",
    sizes: { w: 750, h: 563 },
    imagesName: [
      "1.jpg",
      "2.jpg",
      "3.jpg",
      "4.jpg",
      "5.jpg",
      "6.jpg",
      "7.jpg",
      "8.jpg",
      "9.jpg",
    ],
  },
  {
    title: "Boyl",
    category: "plaster-and-composite",
    date: "2015-2016",
    imageName: "1.jpg",
    sizes: { w: 750, h: 563 },
    imagesName: ["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg"],
  },

  {
    title: "Drawings",
    category: "limited-edition-prints",
    date: "2019-2020",
    imageName: "1.jpg",
    sizes: { w: 800, h: 800 },
    imagesName: ["1.jpg", "2.jpg", "3.jpg"],
  },
  {
    title: "The whole point series",
    category: "limited-edition-prints",
    date: "2017-2018",
    imageName: "1.jpg",
    sizes: { w: 800, h: 800 },
    imagesName: ["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg"],
  },
];

export default worksList;
