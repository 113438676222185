import React, { useState } from "react";

import navLinks from "./navlinks";
import NavSubmenu from "./NavSubmenu";
import { HashLink as Link } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faBars } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";

import itFlag from "./it.png";
import enFlag from "./en.png";

const Navbar = () => {
  const [expand, setExpand] = useState(false);

  const { t, i18n } = useTranslation();

  const setLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <>
      <div
        className="close-navbar"
        onClick={() => {
          setExpand(!expand);
        }}
      >
        <FontAwesomeIcon icon={expand ? faTimes : faBars} />
      </div>
      <nav className={expand ? "navbar" : "navbar hidden-mobile"}>
        <ul className="nav-links">
          {navLinks.map((elem) => {
            if (Array.isArray(elem.submenu)) {
              return <NavSubmenu link={elem} setExpand={setExpand} />;
            } else
              return (
                <li className="nav-link">
                  <Link
                    to={elem.url}
                    className="noselect"
                    onClick={() => {
                      setExpand(false);
                    }}
                  >
                    {t("navbar." + elem.name)}
                  </Link>
                </li>
              );
          })}
          <div className="hline"></div>
          <div className="nav-social-box">
            <a
              href="https://www.instagram.com/matteo_baroni_sculptures/"
              target="_blank"
            >
              <FontAwesomeIcon icon={faInstagram} className="social-icon" />
            </a>
            <a
              href="https://www.instagram.com/matteo_baroni_sculptures/"
              target="_blank"
            >
              <p>@matteo_baroni_sculptures</p>{" "}
            </a>
          </div>
          <div className="langauge-flags">
            <h4
              className={
                i18n.language === "it" ? "it-label selected" : "it-label"
              }
              onClick={() => {
                setLanguage("it");
              }}
            >
              IT
            </h4>
            <h4
              className={
                i18n.language === "en" ? "en-label selected" : "en-label"
              }
              onClick={() => {
                setLanguage("en");
              }}
            >
              EN
            </h4>
            {/* <img
              src={itFlag}
              className="it-flag zoom-3"
              alt="it"
              onClick={() => {
                setLanguage("it");
              }}
            ></img>
            <img
              src={enFlag}
              className="en-flag zoom-3"
              alt="en"
              onClick={() => {
                setLanguage("en");
              }}
            ></img> */}
          </div>
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
