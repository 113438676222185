export const NormalizeWork = (work) => {
  let suffix = "";
  if (typeof work.suffix !== "undefined") {
    suffix = "-" + work.suffix;
  }

  work.gallery = work.imagesName.map((imgUrl) => {
    let url = work.title.toLowerCase().split(" ").join("-") + suffix;
    return `/images/${work.category}/${url}/${imgUrl}`;
  });

  work.image = `/images/${work.category}/${
    work.title.toLowerCase().split(" ").join("-") + suffix
  }/${work.imageName}`;
  return work;
};
