import React from "react";
import ContactForm from "../ContactForm/ContactForm";

const WorkPopup = (props) => {
  return (
    <div
      className={props.work ? "works-popup" : "works-popup hidden"}
      onClick={(e) => {
        if (e.target.className === "works-popup") props.closePopup();
      }}
    >
      <div className="content-box shadow">
        {props.work ? (
          <>
            <p className="bold">
              {props.work.title}, {props.work.date}
            </p>
            <p>{props.work.description}</p>
            <div className="images flex v-flex ai-flex jc-flex">
              {props.work.gallery.map((img) => {
                return <img src={img} alt="" className="work-image" />;
              })}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="content-box shadow">
        <ContactForm />
      </div>
    </div>
  );
};

export default WorkPopup;
