import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const AboutPage = () => {
  const { t } = useTranslation();

  return (
    <div className="padding-box about-box">
      <h1 className="home-title text-center">Matteo Baroni</h1>
      <p>[...]</p>
      <p>{t("about.1p")}</p>
      <p>{t("about.2p")}</p>
      <p>(...)</p>
      <p>{t("about.3p")}</p>
      <p>[...]</p>
      <p className="italic text-right">{t("about.footer-label")}</p>
      <br />
      <br />

      <p>{t("about.4p")}</p>

      <br />
      <p className="text-center">
        <a
          className="button"
          href="/assets/cv-matteo-baroni.pdf"
          target="_blank"
        >
          <FontAwesomeIcon
            icon={faFileDownload}
            style={{ paddingRight: 3, fontSize: 20 }}
          />
          Curriculum Vitae
        </a>
      </p>
    </div>
  );
};

export default AboutPage;
