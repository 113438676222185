import React, { useState } from "react";
import Works from "../../components/Works/Works";
import { HashLink as Link } from "react-router-hash-link";
import InstagramBox from "../../components/InstagramBox";
import { useTranslation } from "react-i18next";

const Home = () => {
  const [descShowMore, setDescShowMore] = useState(true);
  const { t } = useTranslation();

  return (
    <div className="page-section">
      <div className="responsive-box">
        <div className="front-image-box">
          <img src="images/copper-sculptures/squalo/2.jpg" alt="" />
          {/*<iframe
            src="https://player.vimeo.com/video/397422528?background=1&amp;loop=1"
            title="video"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: "5",
            }}
            allow="fullscreen"
            allowfullscreen=""
            dataReady="true"
            frameborder="0"
          ></iframe>*/}
        </div>
      </div>
      <div className="padding-box text-center">
        <p>
          Matteo Baroni (born in Florence 1977) is a contemporary Italian
          artist. His formation starts at the Art Institute of Florence,
          subsequently he graduates at Central Saint Martins College of Art in
          London.
        </p>
        <div style={{ display: descShowMore ? "none" : "unset" }}>
          <p>
            {" "}
            His work, mainly sculpture and land art, addresses issues regarding
            our destructive relationship with the natural environment and
            through his practice he raises awareness on the faults of
            consumerism; unwilling to add other ‘things’ to this world,
            discarded materials become his media of choice, he adapts and
            devises techniques to use them to create refined works of art.
          </p>
        </div>
        <span
          className="button"
          onClick={() => {
            setDescShowMore((p) => !p);
          }}
        >
          {descShowMore ? t("general.show_more") : t("general.show_less")}
        </span>
      </div>
      <Works />
      <InstagramBox />
    </div>
  );
};

export default Home;
