import React, { useState } from "react";
import emailjs from "emailjs-com";
import { useForm } from "react-hook-form";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import ReactLoading from "react-loading";

const ContactForm = (props) => {
  const { t } = useTranslation();
  const [responseMessage, setResponseMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const defaultErrorMessage = t("contacts.message_error");
  const defaultResponseMessage = t("contacts.message_response");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    console.log(errors, data);

    setIsLoading(true);

    const serviceId = "service_0r418tn";
    const templateId = "template_9cxzi8m";
    const userId = "user_rJ41JEW9h9W4MlLAkTuqL";

    const templateParams = {
      name: data.name,
      email: data.email,
      message: data.text,
    };

    emailjs
      .send(serviceId, templateId, templateParams, userId)
      .then((response) => {
        console.log(response);

        setResponseMessage(defaultResponseMessage);
        setErrorMessage("");
        setIsLoading(false);
        reset();
      })
      .catch((error) => {
        console.log(error);

        setResponseMessage("");
        setErrorMessage(defaultErrorMessage);
        setIsLoading(false);
      });
  };
  console.log(errors);
  return (
    <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
      <h3>{t("contacts.leave_a_message")}</h3>
      <textarea
        className="form-textarea"
        style={{
          minHeight: props.size && (props.size === "full" ? "40rem" : "15rem"),
        }}
        placeholder={t("contacts.your_message")}
        {...register("text", { required: true })}
      ></textarea>
      {errors.text && (
        <p className="contact-error">{t("contacts.required_field")}</p>
      )}
      <div className="row-contact flex h-flex">
        <div className="form-element">
          <input
            type="text"
            className="form-input"
            placeholder={t("contacts.your_name")}
            {...register("name", { required: true })}
          />
          {errors.name && (
            <p className="contact-error">{t("contacts.required_field")}</p>
          )}
        </div>

        <div className="form-element">
          <input
            type="text"
            className="form-input"
            placeholder={t("contacts.your_email")}
            {...register("email", { required: true })}
          />
          {errors.email && (
            <p className="contact-error">{t("contacts.required_field")}</p>
          )}
        </div>
      </div>
      <div className="row-contact flex h-flex">
        <div className="form-element">
          <input
            type="checkbox"
            className="form-checkbox"
            {...register("privacypolicy", { required: true })}
          />
          <label for="privacypolicy">
            {t("contacts.agree")}{" "}
            <Link to="/privacy-policy" target="_blank">
              Privacy policy
            </Link>
          </label>
          {errors.privacypolicy && (
            <p className="contact-error">{t("contacts.required_field")}</p>
          )}
        </div>
        {!isLoading && responseMessage.length === 0 && (
          <button type="submit" className="form-button">
            {t("contacts.send")}
          </button>
        )}
        {isLoading && (
          <ReactLoading type="spin" color="#000000" height={20} width={20} />
        )}
      </div>{" "}
      <div className="messages">
        {responseMessage.length > 0 && (
          <div className="response_message">
            <FontAwesomeIcon icon={faCheckCircle} />
            <p>{responseMessage}</p>
          </div>
        )}
        {errorMessage.length > 0 && (
          <div className="error_message">
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <p>{errorMessage}</p>
          </div>
        )}
      </div>
    </form>
  );
};

export default ContactForm;
