import React, { useState } from "react";
import { useParams } from "react-router";
import ImagesGrid from "../../components/ImagesGrid/ImagesGrid";
import WorkPopup from "../../components/WorkPopup";

import worksList from "../../components/Works/worksList";
import { NormalizeWork } from "../../utils/worksUtils";

const Category = (props) => {
  const { category } = useParams();

  let selectedWorks = worksList.filter((work) => {
    return work.category === category;
  });

  const [currentWork, setCurrentWork] = useState(undefined);

  const openPopup = (work) => {
    document.body.style.overflow = "hidden";
    setCurrentWork(work);
  };

  const closePopup = () => {
    document.body.style.overflow = "visible";
    setCurrentWork(undefined);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  let categoryName = category
    .split("-")
    .map((w) => capitalizeFirstLetter(w))
    .join(" ");

  selectedWorks.map((w) => {
    NormalizeWork(w);
    w.onClick = () => {
      openPopup(w);
    };
    return w;
  });

  return (
    <div className="category-page">
      <h1 className="title text-center" style={{ paddingTop: "4rem" }}>
        {categoryName}
      </h1>
      <ImagesGrid images={selectedWorks} mode="grid" />

      <WorkPopup work={currentWork} closePopup={closePopup} />
    </div>
  );
};

export default Category;
